'use strict';

var scrollAnimate = require('base/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
        $('#email-response').css('display', 'block');
        $('#email-response .modal-content span').on('click', function() {
            $('#email-response').css('display', 'none');
        });
        $('#email-response .modal-content').show();
        $('#email-alert-address').val('');
    }
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('.email-alert-signup').submit(function (e) {
        e.preventDefault();
        var url = Urls.emailSignup + '?ajax';
        var button = $(this);
        var emailId =$(this).find('.email-alert-address').val();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    var isModalCookieSet = $.cookie('emailSignupModal');
    var modal = $('#welcome-email-modal').find('.modal-body').html();

    if (modal.length && isModalCookieSet == null && $('.page[data-action="Home-Show"]').length && SitePreferences.ETL_ENABLE_EMAIL_SIGNUP) {
        $('#welcome-email-modal').modal('show');
        $.cookie('emailSignupModal', null, {path: '/'});
        $.cookie('emailSignupModal', 'assetclose', {path: '/',expires: 1825});
    }

    $('#email-modal-signup').submit(function (e) {
        e.preventDefault();
        var url = Urls.emailSignup + '?ajax';
        var button = $(this);
        var emailId =$(this).find('#email-modal-address').val();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                $.spinner().stop();
                if(data.success) {
                    $('#email-response-modal').text('Thank You for Signing Up');
                    $('#email-modal-address').val('');
                }
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

};
